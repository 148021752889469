import React from "react"
import Layout from "@app-components/layout/"
import Grid from "@app-components/ui/grid/"
import Header from "@app-components/ui/header/side_by_side/"
import HeaderImage from "@page-components/products/batch-processor/media/header/"
import HEADERS from "@data/headers/"

const BatchProcessor = () => (
  <Layout
    getStartedButtons={["contact-sales"]}
    page="products/batch-processor"
    getStartedText="Discover the benefit of moving to the cloud.">
    <Header
      title="CineSend Batch Processor"
      text="Web-based platform to build automated high-volume video processing pipelines from ingest through to delivery."
      image={<HeaderImage />}
      buttons={[
        {
          text: "Contact Sales",
          className: "web_white",
          type: "link",
          link: {
            to: "mailto:hello@cinesend.com",
            type: "external"
          }
        }
      ]}
      secondaryNavigation={HEADERS.batch_processor}
    />
    <Grid
      columnWidth={4}
      items={[
        {
          title: "Ingest",
          text:
            "Manually ingest content, create custom delivery portals, ingest from feeds, or use our SDK to build your own ingest applications.",
          _id: 1,
          icon: "download-white"
        },
        {
          title: "Convert",
          text: `Transcode to and from any common video format with Elemental, Carbon, Digital Rapids, Alchemist, and other industry-standard tools.`,
          _id: 2,
          icon: "convert-white"
        },
        {
          title: "QC + Deliver",
          text:
            "Build rule-based workflows with software QC tools, and automatically deliver to endpoints via FTP, Signiant, Aspera, or SmartJog.",
          _id: 3,
          icon: "send-white"
        }
      ]}
    />
  </Layout>
)

BatchProcessor.propTypes = {}

export default BatchProcessor
