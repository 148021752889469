import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import Background from "@app-components/utils/background"
import ButtonWrap from "./button_wrap"
import Navigation from "../components/navigation"

const Header = ({ title, text, buttons = [], image, secondaryNavigation }) => (
  <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="red-single">
    <div
      css={css`
        @media (max-width: 52em) {
          text-align: center !important;
        }
      `}
      className="white py4 px2 md-px3 left-align relative">
      {secondaryNavigation && Object.prototype.hasOwnProperty.call(secondaryNavigation, "title") && (
        <Navigation navigation={secondaryNavigation} />
      )}
      <div className="flex flex-wrap items-center max-width-5 mx-auto mt4 py2">
        <div className="mx-auto col-12 md-col-6 relative z1 mt0 sm-mt3 md-mt0">
          <h1>{title}</h1>
          <p
            css={css`
              font-size: 18px;
              letter-spacing: 1px;
            `}
            className="max-width-3 mt1 mx-auto md-mx0">
            {text}
          </p>
          <div className="block center md-left-align pt2">
            {buttons.map(button => (
              <React.Fragment>
                {button.onClick ? (
                  <div
                    className="mb2 block"
                    css={css`
                      @media (min-width: 320px) {
                        display: inline-block !important;
                        margin-right: 1rem !important;
                      }
                    `}>
                    <button
                      tabIndex={button.type === "button" ? "" : "-1"}
                      onClick={button.onClick}
                      css={css`
                        width: 155px;
                      `}
                      className={`${button.className}`}>
                      {button.text}
                    </button>
                  </div>
                ) : (
                  <ButtonWrap button={button} key={button.text}>
                    <button
                      tabIndex={button.type === "button" ? "" : "-1"}
                      css={css`
                        width: 155px;
                      `}
                      className={`${button.className}`}>
                      {button.text}
                    </button>
                  </ButtonWrap>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          css={css`
            @media (min-width: 40em) and (max-width: 52em) {
              margin-bottom: -6rem;
            }
            height: 350px;
          `}
          className="mx-auto col-12 sm-col-10 md-col-6 mt3 sm-mt1 relative">
          <div
            className="relative sm-absolute pl2 pr2 md-pl3 md-pr2 left-0 right-0"
            css={css`
              top: 50%;
              transform: translateY(-50%);
              @media (max-width: 40em) {
                top: 0;
                transform: none;
              }
            `}>
            {image}
          </div>
        </div>
      </div>
    </div>
  </Background>
)

Header.defaultProps = {
  buttons: [],
  secondaryNavigation: {}
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  image: PropTypes.node.isRequired,
  secondaryNavigation: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    links: PropTypes.array
  })
}

export default Header
